<script lang="ts">
  import { onMount, onDestroy } from 'svelte';
  import { remove } from '../core/store';
  import type { Toast } from '../core/types';

  export let toast: Toast;
  export let dismissable: boolean = false;

  let messageContainer: HTMLDivElement;
  let buttonElement: HTMLElement | null;

  function handleClose() {
    remove();
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleClose();
    }
  }

  onMount(() => {
    if (messageContainer) {
      buttonElement = messageContainer.querySelector('.requestAccessBtn');
      if (buttonElement) {
        buttonElement.addEventListener('click', (e) => {
          e.preventDefault();
          handleClose();
        });
      }
    }
  });

  onDestroy(() => {
    if (buttonElement) {
      buttonElement.removeEventListener('click', handleClose);
    }
  });
</script>

<div class="message tw-relative" {...toast.ariaProps}>
  {#if dismissable}
    <span
      on:click={handleClose}
      role="button"
      tabindex="0"
      aria-label="Close"
      on:keydown={handleKeyDown}
      class="limit-alert-close-button tw-absolute tw-top-4 tw-right-2 tw-px-4 tw-z-10"
    >
      <svg
        class="tw-fill-current tw-h-6 tw-w-6 tw-text-orange-500 tw-border-collapse"
        role="button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        ><title>Close</title><path
          d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
        /></svg
      >
    </span>
  {/if}
  <div bind:this={messageContainer}>
    {#if typeof toast.message === 'string'}
      <!-- eslint-disable-next-line -->
      {@html toast.message}
    {:else}
      <svelte:component this={toast.message} {toast} />
    {/if}
  </div>
</div>

<style>
  .message {
    display: flex;
    justify-content: center;
    margin: 4px 10px;
    color: inherit;
    flex: 1 1 auto;
    /* white-space: pre-line; */
    word-break: break-word;
  }
</style>
